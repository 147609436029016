// @flow

import type { Attributes, SplitClient } from '@splitsoftware/splitio';

import { logger } from '../logger';

/**
 * Overrides SplitClient's `getTreatment` to pull values from local storage first
 * when the DEPLOY_ENV !== 'production. This allows the UAT tests to define
 * feature flags on a per-test basis by writing to local storage.
 */
export function makeGetTreatmentMockable(client: SplitClient): SplitClient {
  const originalGetTreatment = client.getTreatment;
  client.getTreatment = (splitName, attributes: Attributes | void) => {
    let mocks = {};
    try {
      const mocksString = localStorage.getItem('__SIRONA_SPLIT_MOCKS__');
      if (typeof mocksString === 'string') {
        mocks = JSON.parse(mocksString);
      }
    } catch (e) {
      logger.warn('Failed to parse __SIRONA_SPLIT_MOCKS__ from localStorage', e);
    }

    if (splitName in mocks) {
      return mocks[splitName];
    }

    return originalGetTreatment(splitName, attributes);
  };
  return client;
}
