// @flow
import { useState, useMemo } from 'react';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import ViewIcon from '@material-ui/icons/DesktopWindows';
import HelpIcon from '@material-ui/icons/Help';
import ClassIcon from '@material-ui/icons/Class';
import SMSIcon from '@material-ui/icons/Sms';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from '@material-ui/core/SvgIcon';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import { Colors, Sizes } from 'styles';
import GenericSnackBar from 'common/GenericSnackBar/GenericSnackBar';
import type { GenericSnackBarProps } from 'common/GenericSnackBar/GenericSnackBar';
import AccountBox from 'common/Account';
import { ReactComponent as ReporterIconNav } from 'assets/reporterIconNav.svg';
import { ReactComponent as MonitorSettingsIcon } from 'assets/monitorSettingsIcon.svg';
// $FlowIgnore[untyped-import]: TODO(@edrpls): generate flow types
import { useGetUserHasPermissions } from '@sironamedical/common/hooks/useGetUserHasPermissions';

import {
  PATH,
  CHROME_EXTENSION_INSTALL_PAGE,
  MAX_VIEWER_WINDOWS,
  NOT_FOUND_CASE_ID,
} from 'config/constants';
import { MenuProfilePopper } from 'common/ui/MenuProfilePopper';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { useOpenTabs } from 'hooks/useOpenTabs';
import { PAGE_TYPES } from 'utils/pageTypes';
import { transparentize } from 'color2k';
import { MenuItem } from './MenuItem';
import {
  Avatar,
  BackdropBlur,
  SironaLogo,
  ToggleableArea,
  ViewerIconContainer,
  ViewerIndex,
  floatingCss,
  surface,
} from './styles';
import { toggleModal } from 'common/WorkspaceManager';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useStudyIds from 'hooks/useStudyIds';
import { PWATitleBar } from '../ui/PWATitleBar';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { useUrlBuilder } from 'hooks/useUrlBuilder';
import { ADMIN_URL } from 'config';
import { useGetUserRoles } from 'hooks/useGetUserRoles';
import { isWorkspaceManagerAvailable } from 'modules/workspace-manager';

export function Navbar({
  logout,
  toggleable = false,
  floating = false,
}: {
  logout: () => void,
  toggleable?: boolean,
  floating?: boolean,
}): React$Node {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentCaseId = useCurrentCaseId();
  const currentComparativeStudies = useStudyIds();
  const [isURTEnabled] = useFeatureFlagEnabled(FF.URT_ENABLED);
  const buildViewerUrl = useUrlBuilder({ isViewer: true });
  const buildReporterUrl = useUrlBuilder({ isReporter: true });
  const buildURTUrl = useUrlBuilder({ isURT: true });

  const tabs = useOpenTabs();
  const isReporterOpen = tabs.some((tab) => tab.type === PAGE_TYPES.REPORTER);
  const isWorklistOpen = tabs.some((tab) => tab.type === PAGE_TYPES.WORKLIST);
  const isURTOpen = tabs.some((tab) => tab.type === PAGE_TYPES.URT);

  const [snackbarMessage, setSnackbarMessage] = useState<{
    message: React$Node,
    severity: GenericSnackBarProps['severity'],
  }>({ message: null, severity: 'success' });

  const openedViewerIds: Set<string> = useMemo(
    () =>
      new Set(
        tabs
          .filter((tab) => tab.type === PAGE_TYPES.VIEWER)
          .reduce((acc, tab) => (tab.windowId != null ? [...acc, tab.windowId] : acc), [])
      ),
    [tabs]
  );

  const { data } = useCurrentUser();
  const me = data?.me;
  const currUserName = me ? `${me.firstName} ${me.lastName}` : null;
  const zendeskWindowOptions = [
    'https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=sironamedical.zendesk.com',
    '',
    'height=600,width=500',
  ];

  const roles = useGetUserRoles();

  const isRbacEnabled = me?.clinic?.isRbacEnabled ?? false;

  const hasAdminAccess = useGetUserHasPermissions({
    roles: roles ?? [],
    permissionNames: ['Access Admin Panel'],
    component: 'System',
  });

  const viewersButtons = useMemo(
    () =>
      Array.from({ length: MAX_VIEWER_WINDOWS }).map((_, index) => {
        const isViewerOpen = openedViewerIds.has(String(index));
        const isLastButton = index === MAX_VIEWER_WINDOWS - 1;
        const shouldShowNewViewerButton = isLastButton && !isViewerOpen;
        const viewerId = String(shouldShowNewViewerButton ? openedViewerIds.size : index);
        const viewerPath = buildViewerUrl({
          caseSmid: currentCaseId,
          studySmids: currentComparativeStudies,
          viewerId,
        });
        return {
          label: shouldShowNewViewerButton ? 'Open new viewer' : `Open/Show Viewer ${index + 1}`,
          dataTestId: shouldShowNewViewerButton ? 'new-viewer' : 'viewer',
          basePath: PATH.VIEWER.replace(':windowId', viewerId),
          openPath: viewerPath ?? `/case/${NOT_FOUND_CASE_ID}/viewer/${viewerId}`,
          viewerIndex: String(index),
          children: (
            <ViewerIconContainer>
              <ViewIcon />
              <ViewerIndex>
                {openedViewerIds.has(String(index))
                  ? index + 1
                  : index < MAX_VIEWER_WINDOWS - 1
                    ? ''
                    : '+'}
              </ViewerIndex>
            </ViewerIconContainer>
          ),
        };
      }),
    [buildViewerUrl, currentCaseId, currentComparativeStudies, openedViewerIds]
  );

  const menuItems = useMemo(
    () =>
      [
        {
          label: 'Show Worklist',
          dataTestId: 'worklist',
          basePath: PATH.WORKLIST,
          openPath: '/worklist',
          children: <ListIcon />,
        },
        ...viewersButtons,
        {
          label: 'Open/Show Reporter',
          dataTestId: 'reporterNav',
          basePath: PATH.REPORTER,

          openPath:
            (currentCaseId != null &&
              buildReporterUrl({
                caseSmid: currentCaseId,
                studySmids: currentComparativeStudies,
              })) ||
            '/reporter',
          children: <SvgIcon component={ReporterIconNav} />,
        },
        ...(isURTEnabled
          ? [
              {
                label: 'Open URT',
                dataTestId: 'urt',
                basePath: PATH.URT,
                openPath:
                  (currentCaseId != null &&
                    buildURTUrl({
                      caseSmid: currentCaseId,
                      studySmids: currentComparativeStudies,
                    })) ||
                  '/reporter',
                children: <SMSIcon />,
              },
            ]
          : []),
        {
          label: 'Open/Show Product Portal',
          dataTestId: 'product-portal',
          basePath: PATH.PRODUCTPORTAL,
          openPath: '/productportal',

          children: <ClassIcon />,
        },
        ...(isRbacEnabled && hasAdminAccess
          ? [
              {
                label: 'System Administration',
                dataTestId: 'admin',
                basePath: PATH.ADMIN,
                // Local envs point to the admin port, others will handle the redirect through a proxy
                openPath: ADMIN_URL,
                children: <SupervisorAccount />,
                openOnCurrentWindow: false,
                isExternal: true,
              },
            ]
          : []),
      ]
        .filter((x) => x != null)
        .map(
          ({ label, openPath, basePath, children, dataTestId, viewerIndex = '0', isExternal }) => {
            let isRelatedWindowOpen = false;
            if (openedViewerIds.has(viewerIndex) && matchPath(PATH.VIEWER, basePath)) {
              isRelatedWindowOpen = true;
            } else if (isReporterOpen && matchPath(PATH.REPORTER, basePath)) {
              isRelatedWindowOpen = true;
            } else if (isWorklistOpen && matchPath(PATH.WORKLIST, basePath)) {
              isRelatedWindowOpen = true;
            } else if (isURTOpen && matchPath(PATH.URT, basePath)) {
              isRelatedWindowOpen = true;
            }

            return (
              <MenuItem
                key={label}
                selected={matchPath(basePath, pathname === '/' ? '/worklist' : pathname) != null}
                isRelatedWindowOpen={isRelatedWindowOpen}
                label={label}
                to={openPath}
                aria-label={label}
                data-testid={dataTestId}
                setSnackbarMessage={setSnackbarMessage}
                isReporterOpen={isReporterOpen}
                isURTOpen={isURTOpen}
                isWorklistOpen={isWorklistOpen}
                openedViewerIds={openedViewerIds}
                viewerIndex={viewerIndex}
                isExternal={isExternal}
              >
                {children}
              </MenuItem>
            );
          }
        ),
    [
      viewersButtons,
      currentCaseId,
      buildReporterUrl,
      currentComparativeStudies,
      isURTEnabled,
      buildURTUrl,
      isRbacEnabled,
      hasAdminAccess,
      openedViewerIds,
      isReporterOpen,
      isWorklistOpen,
      isURTOpen,
      pathname,
    ]
  );

  const [accountBoxOpen, setAccountBoxOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(!toggleable);

  return (
    <>
      <Stack css={floating ? floatingCss : 'app-region: drag;'} vertical alignX="center" stretchY>
        <Stack
          alignX="center"
          css={`
            ${surface};
            position: relative;
            z-index: 1;
            height: ${Sizes.toolbarHeight};
            margin-top: env(titlebar-area-height, 0);
          `}
        >
          <IconButton
            disabled={!toggleable}
            onClick={() => toggleable && setIsOpen((isOpen) => !isOpen)}
          >
            <SironaLogo />
          </IconButton>
        </Stack>

        <ToggleableArea isOpen={isOpen} css="min-height: 0;">
          <Stack
            vertical
            alignX="center"
            alignY="start"
            stretchY
            css={`
              padding-bottom: 2rem;
              min-height: 0;
              overflow: auto;
              ::-webkit-scrollbar {
                width: 0;
              }
              scrollbar-width: none;
            `}
          >
            {menuItems}
            <Spacer />
            <Stack vertical alignX="center" css="app-region: no-drag;">
              <IconButton
                onClick={
                  isWorkspaceManagerAvailable()
                    ? toggleModal
                    : () => window.open(CHROME_EXTENSION_INSTALL_PAGE, '_blank')
                }
              >
                <MonitorSettingsIcon />
              </IconButton>

              <IconButton
                id="intercom-button"
                onClick={(e) => {
                  window.open(...zendeskWindowOptions);
                }}
              >
                <HelpIcon />
              </IconButton>
              <MenuProfilePopper
                displayName={currUserName}
                onSettingClick={() => setAccountBoxOpen(true)}
                onLogoutClick={() => navigate('/logout')}
                strategy="fixed"
              >
                <IconButton size="small" data-testid="avatar-button">
                  <Avatar name={currUserName} />
                </IconButton>
              </MenuProfilePopper>
            </Stack>
          </Stack>
        </ToggleableArea>
      </Stack>

      <GenericSnackBar
        open={snackbarMessage.message != null}
        handleClose={(_, reason) => {
          reason !== 'clickaway' && setSnackbarMessage((s) => ({ ...s, message: null }));
        }}
        msg={snackbarMessage.message ?? ''}
        severity={snackbarMessage.severity}
      />

      {/*
        Set `disableEnforceFocus` on the MUI dialog as it "steals" focus
        from inputs when a plugin (like 1password) is used.
        https://sironamedical.atlassian.net/browse/EN-4033
      */}
      <Dialog
        open={accountBoxOpen}
        onClose={() => setAccountBoxOpen(false)}
        maxWidth={false}
        disableEnforceFocus
        css={`
          .MuiBackdrop-root {
            background-color: ${transparentize(Colors.gray1, 0.3)};
          }
        `}
      >
        <PWATitleBar />
        <BackdropBlur />
        <AccountBox onClose={() => setAccountBoxOpen(false)} />
      </Dialog>
    </>
  );
}
