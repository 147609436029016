// @flow
import { useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Text from 'common/ui/Text';
import { Stack, Spacer } from 'common/ui/Layout';
import SettingsDropDown from 'common/SettingsDropDown';
import { useDefaultThumbnailSizeSetting } from 'hooks/useViewerSettings';

/** Thumbnail preset sizes. The small/large definitions here will determine the min/max resize
 * height. */
export const THUMBNAIL_PRESET_SIZES_PX = Object.freeze({
  SMALL: 115,
  NORMAL: 135,
  LARGE: 285,
});
const THUMBNAIL_SIZE_FIELD_LABEL = 'Default Thumbnail Size';
export const THUMBNAIL_SIZE_CUSTOM_DROPDOWN_VALUE = { name: 'Custom', value: -1 };
export const THUMBNAIL_SIZE_DROPDOWN = [
  { name: 'Small', value: THUMBNAIL_PRESET_SIZES_PX.SMALL },
  { name: 'Normal', value: THUMBNAIL_PRESET_SIZES_PX.NORMAL },
  { name: 'Large', value: THUMBNAIL_PRESET_SIZES_PX.LARGE },
];

const SettingsMenuItem = withStyles({
  root: {
    '&.Mui-selected': {
      backgroundColor: 'initial',
    },
  },
})(MenuItem);

/** Given a value, attempts to find a matching value for the dropdown. If not found, then it should
 * return the "Custom" preset */
export const valueToThumbnailSizePreset = (sizeValue: ?number): number => {
  if (sizeValue == null) return THUMBNAIL_PRESET_SIZES_PX.NORMAL;
  return sizeValue;
};

export const ThumbnailSizeSettings = (): React$Node => {
  const [thumbnailSizePx, setDefaultThumbnailSize] = useDefaultThumbnailSizeSetting();
  const handleThumbnailSizeChange = useCallback(
    async (eventValue: number) => {
      await setDefaultThumbnailSize(eventValue);
    },
    [setDefaultThumbnailSize]
  );

  return (
    <>
      <Stack alignY="center">
        <Text>{THUMBNAIL_SIZE_FIELD_LABEL}</Text>
        <Spacer />
        <Stack space="small" alignY="center">
          <SettingsDropDown
            value={valueToThumbnailSizePreset(thumbnailSizePx)}
            onSelectedChange={(value) => {
              if (value === THUMBNAIL_SIZE_CUSTOM_DROPDOWN_VALUE.value) return;
              handleThumbnailSizeChange(Number(value));
            }}
            data-testid="thumbnail-size-preference-dropdown"
          >
            {THUMBNAIL_SIZE_DROPDOWN.map((option) => (
              <SettingsMenuItem
                id={`thumbnail-size-preference-menuitem-${option.value}`}
                value={option.value}
                key={option.value}
                data-testid={`${option.value}-menu-item`}
              >
                {option.name}
              </SettingsMenuItem>
            ))}
          </SettingsDropDown>
        </Stack>
      </Stack>
    </>
  );
};
